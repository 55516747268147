<template>
  <div class="home">
    <div class="mainSlide">
      <div class="slideWrap">
        <div class="slideText">
          <h2>{{ $t("product_title_1") }}</h2>
          <p>
            {{ $t("product_context_1") }}
          </p>
        </div>
        <img src="@/assets/images/reviewmind/main_slide.png" alt="이미지" />
      </div>
    </div>
    <div class="mainSection2">
      <div class="section2Wrap">
        <div class="section2Text">
          <h2>{{ $t("product_title_2") }}</h2>
          <p class="section2_title">
            {{ $t("product_context_2") }}
            <br />
          </p>
          <p>
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_2_li_1") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_2_li_2") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_2_li_3") }}
          </p>
          <router-link v-show="!isLogin" to="/join">
            <button>
              Get started
              <i class="fas fa-long-arrow-alt-right"></i>
            </button>
          </router-link>
        </div>
        <img src="@/assets/images/reviewmind/main_image7.png" alt />
      </div>
      <div class="section2Wrap reverse">
        <div class="section2Text">
          <h2>{{ $t("product_title_3") }}</h2>
          <p class="section2_title">
            {{ $t("product_context_3") }}
            <br />
          </p>
          <p>
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_3_li_1") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_3_li_2") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_3_li_3") }}
          </p>
          <router-link v-show="!isLogin" to="/join">
            <button>
              Get started
              <i class="fas fa-long-arrow-alt-right"></i>
            </button>
          </router-link>
        </div>
        <img src="@/assets/images/reviewmind/main_image8.png" alt />
      </div>
      <div class="section2Wrap">
        <div class="section2Text">
          <h2>{{ $t("product_title_4") }}</h2>
          <p class="section2_title">
            {{ $t("product_context_4") }}
            <br />
          </p>
          <p>
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_4_li_1") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_4_li_2") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_4_li_3") }}
          </p>
          <router-link v-show="!isLogin" to="/join">
            <button>
              Get started
              <i class="fas fa-long-arrow-alt-right"></i>
            </button>
          </router-link>
        </div>
        <img src="@/assets/images/reviewmind/main_image6.png" alt />
      </div>
      <div class="section2Wrap reverse">
        <div class="section2Text">
          <h2>{{ $t("product_title_5") }}</h2>
          <p class="section2_title">
            {{ $t("product_context_5") }}
            <br />
          </p>
          <p>
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_5_li_1") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_5_li_2") }}
            <br />
            <span class="material-icons">
              check
            </span>
            {{ $t("product_context_5_li_3") }}
          </p>
          <router-link v-show="!isLogin" to="/join">
            <button>
              Get started
              <i class="fas fa-long-arrow-alt-right"></i>
            </button>
          </router-link>
        </div>
        <img src="@/assets/images/reviewmind/main_image9.png" alt />
      </div>
    </div>

    <div class="mainSection3">
      <div class="section3Wrap">
        <div>
          <img src="@/assets/images/reviewmind/main_image2.png" alt />
          <h2>{{ $t("product_context_6_1") }}</h2>
          <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
        </div>
        <div>
          <img src="@/assets/images/reviewmind/main_image3.png" alt />

          <h2>{{ $t("product_context_6_2") }}</h2>
          <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
        </div>

        <div>
          <img src="@/assets/images/reviewmind/main_image5.png" alt />

          <h2>{{ $t("product_context_6_3") }}</h2>
          <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
        </div>

        <div>
          <img src="@/assets/images/reviewmind/main_image4.png" alt />

          <h2>{{ $t("product_context_6_4") }}</h2>
          <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
        </div>
      </div>
      <div class="section3Text">
        <!-- <img src="@/assets/images/reviewmind/main_image1.png" alt="" /> -->
        <h2>{{ $t("product_title_6") }}</h2>
        <p>
          {{ $t("product_subtitle_6") }}
        </p>
        <button @click="handleMove('more')">More</button>
      </div>
    </div>

    <div class="mainSection4">
      <div class="section4Wrap">
        <h2>{{ $t("demo_request") }}</h2>
        <button @click="handleMove('demo')">Watch Demo</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "swiper-example-loop",
  title: "Loop mode / Infinite loop",

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        // spaceBetween: 160,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption_2: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  created() {
    this.$store.dispatch("GNB_MENU", 1);
  },
  methods: {
    view() {
      this.$router.push("/product/productList?category=2");
    },
    handleMove(type) {
      if (type == "demo") {
        this.$router.push("/WatchDemo");
      } else if (type == "more") {
        this.$router.push("/develop");
      }
    },
  },
};
</script>
